<div class="resend-sms d-flx flex-column align-items-center">
  <div class="resend-sms-body">
    <div *ngIf="showSignMessage" class="resend-sms-content">
      <p class="resend-sms__text text-center">{{ 'RESEND.SIGN_MESSAGE' | translate }}</p>
    </div>
    <div *ngIf="showAuthMessage" class="resend-sms-content">
      <p *ngIf="!timeOut && showPhone" [innerHtml]="'AUTH.SHORT_TEXT' | translate" class="resend-sms__text text-center"></p>
      <p *ngIf="timeOut || !showPhone" class="resend-sms__text text-center">{{ 'CONFIRM.PHONE' | translate }}</p>
    </div>
  </div>
  <div
    [ngClass]="
        showPhone ? 'justify-content-md-between' : 'justify-content-md-center'
      "
    class="resend-sms-footer w-auto text-center d-md-flex"
  >
    <ng-container *ngIf="showPhone">
      <app-button
        (click)="resend(verificationType.PHONE)"
        [label]="'button.repeatPhone' | translate"
        class="w-100"
        color="not-bg"
        size="w100"
      >
      </app-button>
      <p class="text-center">{{ 'bankId.or' | translate }}</p>
    </ng-container>
    <app-button
      (click)="resend(verificationType.SMS)"
      [label]="
          smsViber
            ? ('button.repeatSms_VIBER' | translate)
            : ('button.repeatSms' | translate)
        "
      class="w-100"
      color="not-bg"
      size="w100"
    >
    </app-button>
  </div>
</div>
