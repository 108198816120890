export const environment = {
  production: true,
  name: 'AVANS',
  group: 'AVANS',
  hostUrl: `https://client.gateway.avans.credit/v1/api/rest`,
  isTestPaymentEnabled: false,
  isInnValidationEnabled: true,
  recaptchaKEY_CODE: '6Lde7wMqAAAAAMZIpB-gZw2Ft2xKK6DjDqmb_bH6',
  cookieDomain: '.avans.credit',
  WEBSITE_URL: 'https://avans.credit/',
  CLICK_URL: 'https://click.avans.credit/',
  LOGO_CABINET: '../assets/images/logo.svg',
  LOGO_SITE: '../assets/images/logo.svg',
  LOGO_WHITE: '../assets/images/logo-white.svg',
  CONTACT: 'tel:0800202520',
  partnerLink: 'https://onemoney.com.ua/?sub1=66695581edd4a900018f8287&utm_term=',
  secondPartnerLink: 'https://avans.g2afse.com/click?pid=116&offer_id=46&sub2=',
  GA_TAG: 'CV7DVQM1B1',
  partner_salesDoubler: 'https://loancash.pro/atfzrw',
  helpCranchKey: '6ac39d3a-0b9e-4d1c-93d6-2c2f0a02df53',
  helpCranchOrganization: 'avanscredit',
};
