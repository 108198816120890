<section *ngIf="type === 'middle'; else small" class="preview">
  <div class="preview-wrap">
    <div class="preview-row d-flex justify-content-between">
      <span class="preview__label">{{ 'Ви берете' | translate }}</span>
      <span class="preview__value">{{ preview?.amount }} грн</span>
    </div>
    <div class="preview-row d-flex justify-content-between">
      <span class="preview__label">{{ 'LOAN.PREVIEW.LOAN_TERM' | translate }}</span>
      <span class="preview__value">до {{ preview?.returnDate }}</span>
    </div>
    <div class="preview-row d-flex justify-content-between">
      <span class="preview__label">{{ 'LOAN.PREVIEW.RETURN_AMOUNT' | translate }}</span>
      <span class="preview__value">{{ preview?.totalAmount }} грн</span>
    </div>
    <ng-container *ngIf="showDetails">
      <div [@slideInOut]="animationState">
        <div class="preview-row d-flex justify-content-between">
          <span class="preview__label">{{ 'LOAN.PREVIEW.GENERAL_EXPENSES' | translate }}</span>
          <span class="preview__value">{{ preview?.percentAmount }}</span>
        </div>
        <div class="preview-row d-flex justify-content-between">
          <span class="preview__label">{{ 'LOAN.PREVIEW.DAY_INTEREST' | translate }}</span>
          <span class="preview__value">{{ preview?.rate }}%</span>
        </div>
      </div>
    </ng-container>
  </div>
  <div (click)="toggleShowDetails()" class="preview-toggle d-flex flex-column align-items-center">
    <p *ngIf="!showDetails" class="preview-toggle-text fw-bold text-center">{{ 'Показати більше' | translate }}</p>
    <div [ngClass]="showDetails ? 'arrow-opened' : ''" class="preview-toggle-icon">
      <img alt="arrow" height="26" src="/assets/images/icons/arrow_calculate.webp" width="12" />
    </div>
    <p *ngIf="showDetails" class="preview-toggle-text fw-bold text-center">{{ 'LOAN.PREVIEW.TOGGLE' | translate }}</p>
  </div>
</section>

<ng-template #small>
  <div class="sub-preview">
    <div class="sub-preview-wrap d-flex align-items-center">
      <div class="sub-preview-content d-flex justify-content-around flex-grow-1">
        <div class="sub-preview-item d-flex justify-content-center text-center">
          <span class="sub-preview__label">{{'LOAN.PREVIEW.LOAN_AMOUNT' | translate}}</span>
          <span class="sub-preview__value">{{ preview?.amount }} грн</span>
        </div>
        <div class="sub-preview-item d-flex justify-content-center text-center">
          <span class="sub-preview__label">{{'LOAN.PREVIEW.TERM' | translate}}</span>
          <span class="sub-preview__value">{{ preview?.term }} днів</span>
        </div>
        <div class="sub-preview-item d-flex justify-content-center text-center">
          <span class="sub-preview__label">{{'LOAN.PREVIEW.RETURN' | translate}}</span>
          <span class="sub-preview__value">{{ preview?.totalAmount }} грн</span>
        </div>
      </div>
      <button (click)="openModal()" *ngIf="isLogged" class="sub-btn" mat-mini-fab>
        <img alt="icon" src="./assets/images/icons/icon-pen.svg" />
      </button>
    </div>
  </div>
</ng-template>
