<ng-container *ngIf="isWebSite; then landing; else cabinet"></ng-container>

<ng-template #landing>
  <header class="header header-landing">
    <div class="header-wrap">
      <div class="header-inner d-flex align-items-center justify-content-between">
        <div [ngClass]="{ active: hamburger }" class="header-logo">
          <a [href]="environment.WEBSITE_URL">
            <img [src]="env.LOGO_SITE" alt="" class="header-logo-icon" height="100%" width="100%" />
          </a>
        </div>
        <div class="header-content">
          <app-header-navigation
            (hamburgerEvent)="hamburgerEventHandler()"
            (logoutEvent)="logoutHandler()"
            [active]="hamburger"
            [env]="env"
            [isLogged]="isLogged$ | async"
            [isMenu]="true"
            [isMobile]="tabletWide$ | async"
            [isWebSite]="isWebSite"
            [navItems]="webSiteItems"
            [phone]="phone"
          ></app-header-navigation>

          <div class="header-container">
            <div class="phone">
              <img alt="phone AvansCredit" class="phone-icon" src="assets/images/phone2.svg" />
              <div class="phone-content d-flex align-items-center justify-content-center text-center">
                <a [href]="[phone.contact]" class="phone-link">{{ phone.label }}</a>
              </div>
            </div>

            <a (click)="login()" class="login-btn d-flex align-items-center"> Увійти </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</ng-template>

<ng-template #cabinet>
  <header [class.mb-4]="!(isPreview$ | async) && isStepsVisible" [class.mb-5]="(isPreview$ | async) && isStepsVisible" class="header">
    <div class="header-wrap">
      <div class="header-inner d-flex align-items-center justify-content-between">
        <div [ngClass]="{ active: hamburger }" class="header-logo">
          <a [href]="environment.WEBSITE_URL">
            <img [src]="env.LOGO_SITE" alt="" class="header-logo-icon" height="100%" width="100%" />
          </a>
        </div>

        <div class="header-content d-flex justify-content-end">
          <ng-container *ngIf="isLogged$ | async">
            <app-header-navigation
              (hamburgerEvent)="hamburgerEventHandler()"
              (logoutEvent)="logoutHandler()"
              [active]="hamburger"
              [env]="env"
              [isMenu]="isMenu$ | async"
              [isMobile]="tabletWide$ | async"
              [navItems]="navItems"
              [phone]="phone"
            ></app-header-navigation>
          </ng-container>

          <div class="header-container">
            <div class="phone d-flex align-items-center">
              <img alt="phone AvansCredit" class="phone-icon" src="assets/images/phone.svg" />
              <div class="phone-content d-flex align-items-center justify-content-center text-center">
                <a [href]="[phone.contact]" class="phone-link">
                  {{ phone.label }}
                  <br />
                  <span class="phone-text">безкоштовно</span>
                </a>
              </div>
            </div>

            <ng-container *ngIf="isLogged$ | async">
              <div (click)="logoutHandler()" class="logout d-flex align-items-center" mat-mini-fab>
                <svg class="logout-icon" fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.25 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V4.58333C2.75 4.0971 2.94315 3.63079 3.28697 3.28697C3.63079 2.94315 4.0971 2.75 4.58333 2.75H8.25"
                    stroke=""
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path
                    d="M14.6667 15.5834L19.25 11L14.6667 6.41669"
                    stroke=""
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <path d="M19.25 11H8.25" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                </svg>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="header-footer">
      <ng-container *ngIf="(isPreview$ | async) && (isLogged$ | async)">
        <app-loan-preview
          [group]="env.group"
          [isLogged]="isLogged$ | async"
          [preview]="preview$ | async"
          [type]="'small'"
        ></app-loan-preview>
      </ng-container>
      <app-stepper-widget (onVisibleChange)="onStepsVisibleChange($event)" [steps]="steps" [type]="env.group"></app-stepper-widget>
    </div>
  </header>
</ng-template>
