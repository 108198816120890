import { Component, Input } from '@angular/core';
import { IContact } from '../../../models/models';

@Component({
  selector: 'app-phone-widget',
  templateUrl: './phone-widget.component.html',
  styleUrls: ['./phone-widget.component.sass'],
})
export class PhoneWidgetComponent {
  @Input()
  public phone: IContact;
}
