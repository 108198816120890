<section [formGroup]="form" class="auth-confirm">
  <div class="auth-confirm-content">
    <h2 class="auth-confirm-title text-center">{{ 'AUTH.TITLE' | translate }}</h2>
    <p class="auth-confirm-text">
      <span [innerHTML]="'AUTO_CONFIRM.CALL' | translate : { number: phoneNumber }"></span>
      <span (click)="showPhoneEditorEvent.emit()" class="cursor-pointer font-weight-bold">({{ 'AUTH.EDIT' | translate }})</span>
    </p>
    <p [innerHTML]="'AUTO_CONFIRM.CLICK' | translate" class="auth-confirm-text"></p>
    <div class="auth-confirm-icon d-flex justify-content-center">
      <svg class="call-btn" fill="none" height="60" viewBox="0 0 61 60" width="61" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M30.5 60C47.3447 60 61 46.5685 61 30C61 13.4315 47.3447 0 30.5 0C13.6553 0 0 13.4315 0 30C0 46.5685 13.6553 60 30.5 60ZM30.9046 35.4603C28.217 34.1673 26.0464 32.0023 24.7466 29.3181C24.6533 29.1232 24.6116 28.9076 24.6254 28.692C24.6393 28.4764 24.7082 28.2679 24.8256 28.0865L27.4624 24.0759C27.5741 23.9014 27.6412 23.7021 27.6577 23.4956C27.6742 23.2891 27.6397 23.0817 27.5572 22.8917L24.3834 15.4863C24.2783 15.2338 24.0931 15.0228 23.8563 14.8858C23.6195 14.7489 23.3442 14.6936 23.0729 14.7284C21.2449 14.9626 19.5648 15.8549 18.3471 17.2383C17.1294 18.6217 16.4574 20.4013 16.457 22.2443C16.457 27.9396 18.7195 33.4015 22.7466 37.4287C26.7738 41.4558 32.2357 43.7183 37.931 43.7183C39.774 43.7178 41.5536 43.0459 42.937 41.8282C44.3204 40.6105 45.2127 38.9304 45.4469 37.1024C45.4817 36.8311 45.4264 36.5558 45.2895 36.319C45.1525 36.0822 44.9415 35.897 44.689 35.7918L37.2994 32.6181C37.1063 32.5337 36.895 32.4995 36.6852 32.5189C36.4753 32.5382 36.2738 32.6104 36.0994 32.7287L32.152 35.3655C31.9702 35.4883 31.7595 35.5615 31.5408 35.5782C31.3221 35.5948 31.1028 35.5541 30.9046 35.4603Z"
          fill="#00B92D"
          fill-rule="evenodd"
        />
      </svg>
    </div>
    <ng-container [class.d-none]="timeOut">
      <app-countdown-container
        (breakpointOutEvent)="breakpointOutHandler($event)"
        (timeOutEvent)="timeOutEventHandler($event)"
        [counterType]="'TIMER'"
        [group]="group"
        [initialState]="initialState"
        [verificationType]="verificationType"
        classes="mt-2"
      ></app-countdown-container>
    </ng-container>
    <ng-container *ngIf="breakpointTimeOut">
      <app-resend-sms-widget
        (verifyWayEvent)="wayVerificationHandler($event)"
        [group]="group"
        [showAuthMessage]="true"
        [showPhone]="showPhone"
        [timeOut]="timeOut"
      ></app-resend-sms-widget>
    </ng-container>
    <div class="mt-3">
      <app-auth-agreement formGroupName="agreement" labelClasses="text-justify"></app-auth-agreement>
    </div>
  </div>
</section>
