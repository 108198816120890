<app-dialog [showCloseBtn]="true" size="xLarge">
  <section class="loan-editor" dialogContent>
    <div [formGroup]="form" class="loan-editor-content">
      <div class="loan-editor-calculate">
        <h2 class="loan-editor-header fw-bold text-center">{{ 'Зміна параметрів кредиту' | translate }}</h2>
        <app-calculate-widget
          (calculateChangeEvent)="calculateChangeHandler($event)"
          [amount]="amount"
          [group]="data.group"
          [isDisabledPromo]="isDisabledPromo"
          [product]="data.product"
          [term]="term"
          formGroupName="calculate"
        ></app-calculate-widget>
      </div>
      <div class="loan-editor-info d-flex flex-column align-items-center">
        <app-promo-code
          [isDisabled]="isDisabledPromo"
          [isSpinner]="showSpinner"
          [maxCodeLength]="MIN_CODE_LENGTH"
          [message]="message"
          [placeholder]="'Введіть промокод тут'"
          [type]="'avans'"
          formControlName="code"
        ></app-promo-code>
        <div class="w-100 mt-4 mb-3">
          <app-loan-schedule-widget
            [character]="'WORKTABLE'"
            [preview]="preview$ | async"
            [schedule]="schedule$ | async"
            [type]="'avans'"
          ></app-loan-schedule-widget>
        </div>
        <app-button (click)="setChanges()" [label]="'BUTTON.EMPLOY' | translate" classes="fs-20" color="secondary" size="large">
        </app-button>
      </div>
    </div>
  </section>
</app-dialog>
