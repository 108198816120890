import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (!environment.production) {
  // indexing site on production
  const metaTag = document.getElementById('meta-tag-for-robots') as HTMLMetaElement;
  if (metaTag) {
    metaTag.content = 'noindex, nofollow';
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
