<section class="auth-phone">
  <h1 class="auth-phone-title text-center fw-bold">{{ 'AUTH.TITLE' | translate }}</h1>
  <form [formGroup]="loginForm" class="form">
    <div class="form-body">
      <div class="form__row">
        <label [innerHTML]="'AUTH.SUBTITLE' | translate" class="form__label text-center" for="phone"></label>
        <div class="form__field">
          <input
            #phoneInput
            (click)="setValuePhone($event.target)"
            (keydown)="preventDefaultValueDeletion($event)"
            (keyup)="inputPhoneNumber($event.target)"
            [minLength]="18"
            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
            autocomplete="on"
            class="form__input input"
            formControlName="phone"
            id="phone"
            mask="00(000)000-00-00"
            placeholder="380"
            type="tel"
          />
        </div>
        <div *ngIf="submitted && f.phone.errors" class="form-error">
          <span *ngIf="f.phone.errors.required" translate>validation.required</span>
          <span *ngIf="f.phone.errors.pattern" translate>validation.phone</span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <app-button
        (click)="onSubmitRikki()"
        [disabled]="isDisabled"
        [label]="'BUTTON.CONTINUE' | translate"
        classes="fs-20"
        color="secondary"
        size="large"
      >
      </app-button>

      <app-button
        (click)="onSubmit()"
        *ngIf="!production"
        [disabled]="isDisabled"
        [label]="'4 останні цифри дев' | translate"
        [type]="'submit'"
        color="pure"
        size="large"
      ></app-button>
    </div>
  </form>
</section>
