<app-dialog [showCloseBtn]="true" [size]="'medium'">
  <div class="auth-phone-edit" dialogContent>
    <h2 class="auth-phone-edit-header text-center">{{ 'AUTH_PHONE_MODAL.TITLE' | translate }}</h2>
    <p [innerHTML]="'AUTH_PHONE_MODAL.INFO' | translate : { number: phoneNumber }" class="auth-phone-edit-info text-center"></p>
    <form [formGroup]="form" class="form">
      <div class="form-body">
        <div class="form__row">
          <div class="form__field">
            <input
              #phoneInput
              (click)="setValuePhone($event.target)"
              (keydown)="preventDefaultValueDeletion($event)"
              (keyup)="inputPhoneNumber($event.target)"
              [minLength]="18"
              [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
              autocomplete="on"
              class="form__input input"
              formControlName="phone"
              id="phone"
              mask="00(000)000-00-00"
              placeholder="380"
              type="tel"
            />
          </div>
          <div *ngIf="submitted && f.phone.errors" class="form-error">
            <span *ngIf="f.phone.errors.required" translate>validation.required</span>
            <span *ngIf="f.phone.errors.pattern" translate>validation.phone</span>
          </div>
        </div>
      </div>
    </form>
    <div class="auth-phone-edit-footer d-flex flex-column align-items-center">
      <app-button
        (click)="updatePhone()"
        [label]="'AUTH_PHONE_MODAL.BTN' | translate"
        class="w-100"
        classes="fs-15 fw-bold"
        color="primary"
        size="w100"
        type="button"
      ></app-button>
    </div>
  </div>
</app-dialog>
