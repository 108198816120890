import * as Models from '../../../../../libs/models/models';
import { Schedule } from '@libs/types/shedule.type';
import { NavItem } from '@libs/models/nav-item.interface';
import { ISocial } from '@libs/models/models';

export class Config {
  static phones: Array<Models.IContact> = [
    {
      label: '0 800 20 25 20',
      contact: 'tel:0800202520',
    },
    {
      label: '+38 093 020 25 20',
      contact: 'tel:+380930202520',
    },
  ];

  static emails: Array<Models.IContact> = [
    {
      label: 'mailto:info@avans.credit',
      contact: 'info@avans.credit',
    },
    {
      contact: 'marketing@avans.credit',
      label: 'mailto:marketing@avans.credit',
    },
  ];
}

export const schedule: Schedule[] = [
  {
    days: 'Пн-Пт',
    hours: '9:00 - 18:00',
  },
  {
    days: 'Сб-Нд',
    hours: 'вихідні',
  },
];

export const navItems: NavItem[] = [
  {
    label: 'NAVIGATION.LOAN',
    route: 'main/loan',
  },
  {
    label: 'NAVIGATION.HISTORY',
    route: 'main/history',
  },
  {
    label: 'NAVIGATION.PROFILE',
    route: 'main/profile',
  },
  {
    label: 'NAVIGATION.UPLOAD',
    route: 'main/upload',
  },
];

export const webSiteItems: NavItem[] = [
  {
    label: 'Публічна інформація',
    route: '/documents',
  },
  {
    label: 'Поширені питання',
    route: '/questions',
  },
  {
    label: 'Контакти',
    route: '/contacts',
  },
  {
    label: 'Блог',
    route: '/blog',
  },
];

export const navItemsFooter: NavItem[] = [
  {
    label: 'NAVIGATION.QUESTIONS',
    route: '/questions',
  },
  {
    label: 'NAVIGATION.CONTACTS',
    route: '/contacts',
  },
  {
    label: 'NAVIGATION.DOCS',
    route: '/documents',
  },
];

export const socialMedia: ISocial[] = [
  {
    href: 'https://www.facebook.com/profile.php?id=61551956880519',
    alt: 'facebook',
    img: 'assets/images/fb.svg',
  },
  {
    href: 'https://www.instagram.com/avanscredit/',
    alt: 'instagram',
    img: 'assets/images/inst.svg',
  },
];
