import { UrlTree, DefaultUrlSerializer } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const currentUrl = url.split('?');
    const link = currentUrl[0];
    const param = currentUrl[1] ? '?' + currentUrl[1] : '';
    return super.parse(link.toLowerCase() + param);
  }
}
