import { InjectionToken } from '@angular/core';

export type Apply = {
  minTerm: number;
  maxTerm: number;
  minAmount: number;
  maxAmount: number;
  minRate: number;
  maxRate: number;
  actionRate: number;
  startTerm: number;
  startAmount: number;
  totalAmount?: number;
  creditTerm: number;
  termStep: number;
};

export const AVANS: Apply = {
  minTerm: 10,
  maxTerm: 25,
  minAmount: 1000,
  maxAmount: 20000,
  minRate: 0.99,
  maxRate: 0.99,
  actionRate: 0.01,
  startTerm: 15,
  startAmount: 10000,
  creditTerm: 120,
  termStep: 1,
};

export const FINBAR: Apply = {
  minTerm: 10,
  maxTerm: 25,
  minAmount: 1000,
  maxAmount: 20000,
  minRate: 0.99,
  maxRate: 0.99,
  actionRate: 0.01,
  startTerm: 15,
  startAmount: 10000,
  creditTerm: 120,
  termStep: 1,
};

export const CREDISEND: Apply = {
  minTerm: 10,
  maxTerm: 25,
  minAmount: 1000,
  maxAmount: 20000,
  minRate: 0.99,
  maxRate: 0.99,
  actionRate: 0.01,
  startTerm: 15,
  startAmount: 10000,
  creditTerm: 120,
  termStep: 1,
};

export const CALCULATE_TOKEN = new InjectionToken<Apply>('config');
