<section *ngIf="routes$ | async as routes;" class="stepper">
  <div class="stepper-wrapper d-flex justify-content-between align-items-center">
    <div class="steps d-flex align-items-center">
      <div *ngFor="let item of routes;" [class.current]="item.current" class="steps-item">
        <div class="steps-icon"></div>
        <div class="steps-label">{{ item.text }}</div>
      </div>
    </div>
    <div class="stepper-percent">{{ totalProgress }}%</div>
  </div>
</section>
